import React, { FC } from 'react';
import MobileLandingComponent from './components/MobileLandingComponent';
import './App.less';

const App: FC = () => (
  <div className="App">
    <MobileLandingComponent />
  </div>
);

export default App;
