import config from '../config.json';

export async function http<T>(
    request: RequestInfo, headerDict: any = {}
  ): Promise<T> {
    
    let server_endpoint = config.server_path_prod;
    if (process.env.NODE_ENV === "development") {
        server_endpoint = config.server_path_dev;
    }
    let endpoint = server_endpoint + request
    headerDict['Content-Type'] = 'application/json';

    const response = await fetch(endpoint, {
        credentials: 'include',
        headers: headerDict,
    });
    if (response.status === 200) {
        const body = await response.json();
        return body;
    }
    const body = await response.json();
    const message = body["message"];
    return Promise.reject(new Error(message))
}
  
export default {
    http: http
}