import fetch from '../fetcher';

export default class UserService {
    public static shared = new UserService();
    private constructor() {}

    public async joinWaitlist(email: String): Promise<null|boolean> {
        return fetch.http(`/waitlist?email=${email}`)
            .then((response: any) => {
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            })
    }
}