import React from 'react';

import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import { Layout, Button, Input, Form } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import UserService from './UserService';

const { Footer } = Layout;

export default class MobileLandingComponent extends React.Component {
    userService: UserService = UserService.shared;

    state = {
        isLoading: false,
        hasSubmittedEmail: false
    }

    didSelectJoinWaitlist(e: any) {
        this.setState({isLoading: true});
        
        let email = e.email;

        setTimeout(() => {
            this.setState({isLoading: false, hasSubmittedEmail: true});
        }, 2000);

        // this.userService.joinWaitlist(email)
        //     .then((state: null|boolean) => {
        //         if (state === true) {
        //             this.setState({isLoading: false, hasSubmittedEmail: true});
        //         } else {
        //             this.setState({isLoading: false, hasSubmittedEmail: false});
        //         }
        //     })
        //     .catch(() => {
        //         this.setState({isLoading: false, hasSubmittedEmail: false});
        //     });
    }

    renderTextbox() {
        if (this.state.hasSubmittedEmail) {
            return (
                <p style={{color: 'white'}}>
                    Thanks for signing up!
                    <br />
                    We'll notify you soon.
                    <br />
                    🎉
                </p>
            )
        }
        return (
            <Form
                name="join_waitlist"
                initialValues={{ remember: true }}
                onFinish={(e) => this.didSelectJoinWaitlist(e)}
                >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please enter your Email!' }]}
                >
                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Enter your email" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit"
                        loading={this.state.isLoading}>
                        {this.renderButtonCopy()}
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    renderButtonCopy() {
        if (this.state.hasSubmittedEmail) {
            return "Thank you!"
        }
        return "Join Waitlist!";
    }

    renderChildren() {
        return (
            <div>
                <img
                    alt="example"
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    height='96px'
                />
                <h1 style={{color: 'white', fontSize: "44px", letterSpacing: '2px'}}>
                    <Texty>
                        Rainbow Labs Inc.
                    </Texty>
                </h1>
                <p style={{color: 'white'}}>We are building products in the space of consumer media.</p>
                <br/>
                {/* <p style={{color: 'white'}}>More coming soon.</p> */}
                <br />
                {this.renderTextbox()}
                <br />
                <br />
            </div>
        )
    }

    render() {
        return (
            <div>
                <Layout className="site-layout"
                    style={{
                        // width: '100vh',
                        minHeight: '100vh',
                        backgroundColor: '#343447'
                }}>
                    {/* <Alert message="Please visit the website on a desktop for the best experience." banner /> */}
                    <Footer style={{ backgroundColor:'#343447' }}></Footer>
                    <Layout style={{
                        backgroundColor: '#343447',
                        justifyContent: 'center',
                        display: 'flex',
                        margin: 'auto',
                    }}>
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}>
                            {this.renderChildren()}
                        </QueueAnim>
                    </Layout>
                    
                    <Footer style={{ textAlign: 'center', backgroundColor:'#343447' }}>
                        <p style={{color: 'white', fontSize: '12px'}}>
                            Rainbow Labs Inc. © {`${new Date().getFullYear()}`}
                        </p>
                    </Footer>
                </Layout>
            </div>
        )
    }
}